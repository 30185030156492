import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import { getDownloadURL, ref as storageRef } from "firebase/storage"

import { useWavesurfer } from "hooks/wavesurfer.hook"
import { WAVESURFER_PLAYER_OPTIONS } from "constants/wavesurfer.constants"
import {
  ButtonTypeEnum,
  PlayerButtonTypeEnum,
} from "types/common.types"
import MButton from "components/base/MButton"
import PlayerButton from "components/shared/PlayerButton.component"
import ContentWrapper from "components/content-wrapper/ContentWrapper.component"
import PlayerControls from "components/player-controls/PlayerControls.component"
import { storage } from "../../../firebase"

// assets
import { ReactComponent as CrossIcon } from "assets/icons/cross-icon.svg"
import cn from "classnames"

interface AudioFullscreenProps {
  messageId: string
}

const AudioFullscreen: FC<AudioFullscreenProps> = ({ messageId }) => {
  const [showFullscreen, setShowFullscreen] = useState(false)
  const [audioUrl, setAudioUrl] = useState<string | null>(null)

  const wavesurferRef = useRef(null)
  const modalRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    getDownloadURL(storageRef(storage, `audio/${messageId}.mp3`)).then(
      (url) => {
        setAudioUrl(url)
      },
    )
  }, [messageId])

  const wavesurferOptions = useMemo(
    () => ({
      ...WAVESURFER_PLAYER_OPTIONS,
      ...(audioUrl
        ? {
          url: audioUrl,
        }
        : {}),
    }),
    [audioUrl],
  )

  const { wavesurfer, currentTime, isPlaying, isFinished, duration } =
    useWavesurfer(wavesurferRef, wavesurferOptions)

  const handleClickOnCloseModal = useCallback(() => {
    setShowFullscreen(false)
    enableBodyScroll(modalRef.current)
    wavesurfer?.stop()
  }, [wavesurfer])

  const handleClickOnPlay = useCallback(() => {
    if (!showFullscreen) {
      setShowFullscreen(true)
      disableBodyScroll(modalRef.current)
    }
    wavesurfer?.play()
  }, [wavesurfer])

  const handleClickOnPause = useCallback(() => {
    wavesurfer?.pause()
  }, [wavesurfer])

  useEffect(() => {
    return () => {
      wavesurfer?.destroy()
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <>
      <div
        ref={modalRef}
        className={cn("fixed top-0 left-0 right-0 w-screen flex flex-col h-full justify-between z-50 bg-beige overflow-y-auto", {
          hidden: !showFullscreen,
        })}
      >
        <div className="relative flex flex-col mx-auto max-w-md w-full h-full gap-4 p-2 pt-24">
          <ContentWrapper
            showProgressBar
            currentTime={currentTime}
            duration={duration}
            progressBarTransitionDuration="0.3s"
            wrapperClassName="p-11 flex items-center"
          >
            <div ref={wavesurferRef} className="max-h-32 h-full w-full" />
          </ContentWrapper>
        </div>
        <MButton type={ButtonTypeEnum.Icon} onClick={handleClickOnCloseModal}>
          <CrossIcon className="absolute top-4 left-4 w-5 h-5 text-dark-grey z-30" />
        </MButton>
        <PlayerControls
          isPlaying={isPlaying}
          isFinished={isFinished}
          onPlay={handleClickOnPlay}
          onPause={handleClickOnPause}
          onReplay={handleClickOnPlay}
        />
      </div>
      {!showFullscreen ? (
        <div
          className="absolute top-0 left-0 right-0 w-full h-full cursor-pointer"
          onClick={handleClickOnPlay}
        >
          <PlayerButton
            type={PlayerButtonTypeEnum.Play}
            className="absolute-center"
            size="lg"
            withShadow
          />
        </div>
      ) : null}
    </>
  )
}

export default memo(AudioFullscreen)
