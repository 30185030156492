import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react"
import cn from "classnames"
import { Stream, StreamPlayerApi } from "@cloudflare/stream-react"
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import axios, { AxiosResponse } from "axios"

import { ButtonTypeEnum, PlayerButtonTypeEnum } from "types/common.types"
import { useStream } from "hooks/stream.hook"
import MButton from "components/base/MButton"
import MProgressBar from "components/base/MProgressBar"
import PlayerControls from "components/player-controls/PlayerControls.component"
import PlayerButton from "components/shared/PlayerButton.component"
import { CLOUDFLARE_CONFIG, getRetrieveVideoDetailsApi } from "constants/api.constants"
import { poll } from "utils/common.utils"
import { VideoDetails } from "types/video-details.types"

// assets
import { ReactComponent as CrossIcon } from "assets/icons/cross-icon.svg"
import { usePlayer } from "../../../context/player.context"

interface VideoFullscreenProps {
  messageId: string
  isBcMessage?: boolean
  onVideoProcessed?: () => void;
}

const VideoFullscreen: FC<VideoFullscreenProps> = ({
                                                     messageId,
                                                     isBcMessage = false,
                                                     onVideoProcessed,
                                                   }) => {
  const [showFullscreen, setShowFullscreen] = useState(false)

  const playerRef = useRef<StreamPlayerApi>(null!)
  const modalRef = useRef<HTMLDivElement>(null!)

  const {
    isPlaying,
    isFinished,
    startPlaying,
    pausePlaying,
    replay,
    leavePlayer,
    onEnded,
    currentTime,
    onTimeUpdate,
  } = useStream(playerRef)

  const {isEcVideoProcessing} = usePlayer()

  const handleClickOnPlay = useCallback(() => {
    disableBodyScroll(modalRef.current)
    setShowFullscreen(true)
    startPlaying()
  }, [])

  const handleClickOnCloseModal = useCallback(() => {
    leavePlayer()
    setShowFullscreen(false)
    enableBodyScroll(modalRef.current)
  }, [])

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (!isEcVideoProcessing || isBcMessage) return

    const fetchVideoDetails = () =>
      axios.get(getRetrieveVideoDetailsApi(messageId), CLOUDFLARE_CONFIG)

    const isProcessing = (response: AxiosResponse<VideoDetails>) => {
      return !response.data.result.readyToStream
    }

    void poll(fetchVideoDetails, isProcessing, 3000).then(() => {
      onVideoProcessed?.()
    })
  }, [messageId, isEcVideoProcessing])

  if (isEcVideoProcessing && !isBcMessage) {
    return (
      <div className="absolute top-0 left-0 right-0 w-full h-full">
        <div className="spinner top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute">
          <div className="animate-spin rounded-full border-t-4 border-t-red w-20 h-20" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        ref={modalRef}
        className={cn("fixed top-0 left-0 right-0 w-screen h-full z-50 bg-beige overflow-y-auto",
          { hidden: !showFullscreen })}
      >
        {messageId ? (
          isBcMessage ? (
            <>
              <Stream
                key={messageId}
                streamRef={playerRef}
                src={messageId}
                className="!static child-iframe:pointer-events-none"
                onEnded={onEnded}
                onPlay={startPlaying}
                onTimeUpdate={onTimeUpdate}
                letterboxColor="#E6E3CF"
              />
              <MProgressBar
                completed={(currentTime * 100) / playerRef.current?.duration}
                className="absolute bottom-0 left-0 right-0"
                transitionDuration="0.3s"
                height="4px"
              />
            </>
          ) : (
            <div className="flex flex-col h-fit pt-24 mx-auto max-w-md rounded-md">
              <div className="relative m-2">
                <div style={{ transform: `scaleX(-1)` }}>
                  <Stream
                    key={messageId}
                    streamRef={playerRef}
                    controls={false}
                    src={messageId}
                    className="w-full h-full mx-auto child-iframe:rounded-md"
                    onEnded={onEnded}
                    onPlay={startPlaying}
                    onTimeUpdate={onTimeUpdate}
                    letterboxColor="#E6E3CF"
                  />
                </div>
                <MProgressBar
                  completed={(currentTime * 100) / playerRef.current?.duration}
                  className="absolute bottom-0 left-0 right-0"
                  transitionDuration="0.3s"
                />
              </div>
            </div>
          )
        ) : null}
        <MButton type={ButtonTypeEnum.Icon} onClick={handleClickOnCloseModal}>
          <div className="absolute top-4 left-4 z-30 p-1 rounded-full bg-beige bg-opacity-50">
            <CrossIcon className="w-5 h-5 text-dark-grey" />
          </div>
        </MButton>
        <PlayerControls
          isPlaying={isPlaying}
          isFinished={isFinished}
          onPlay={startPlaying}
          onPause={pausePlaying}
          onReplay={replay}
        />
      </div>
      {!showFullscreen ? (
        <div
          className="absolute top-0 left-0 right-0 w-full h-full cursor-pointer"
          onClick={handleClickOnPlay}
        >
          <PlayerButton
            type={PlayerButtonTypeEnum.Play}
            className="absolute-center"
            size="lg"
            withShadow
          />
        </div>
      ) : null}
    </>
  )
}

export default memo(VideoFullscreen)
