import React, { FC } from "react"

interface OverlayProps {
  shown?: boolean
}

const MOverlay: FC<OverlayProps> = ({ shown = false }) => {
  return shown ? (
    <div className="fixed top-0 left-0 w-screen min-h-screen h-full bg-black bg-opacity-30 z-40" />
  ) : null
}

export default MOverlay
