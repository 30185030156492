import React, { FC } from "react"
import { RecordingStatusEnum } from "types/recorder.types"
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg"
import cn from "classnames"

interface RecorderNoteProps {
  status: RecordingStatusEnum
}

const RecorderNote: FC<RecorderNoteProps> = ({ status }) => {
  const colorOpacity =
    status === RecordingStatusEnum.Reviewing ? "opacity-40" : "opacity-80"

  return (
    <div className={cn("w-full flex justify-center text-center text-dark-grey text-lg", colorOpacity,
      { "invisible": status === RecordingStatusEnum.Recording })}>
      {status === RecordingStatusEnum.Reviewed ? (
        <p className="flex gap-2 items-center">
          <Checkmark />
          Geprüft
        </p>
      ) : status === RecordingStatusEnum.Reviewing ||
      status === RecordingStatusEnum.Stopped ? (
        "Aufnahme prüfen"
      ) : (
        "Sie haben 30 Sekunden Zeit."
      )}
    </div>
  )
}

export default RecorderNote
