import { PLAYER_CONFIG } from "./player.constants"

export const CLOUDFLARE_CONFIG = {
  headers: {
    // TODO: move to env
    Authorization: `Bearer RSG9Q-5wd_N_Ufux8e0nOqQVxlrHhpY1fPTCJsJV`
  }
};

/** See details https://developers.cloudflare.com/api/operations/stream-videos-retrieve-video-details */
export const getRetrieveVideoDetailsApi = (id: string) => `https://api.cloudflare.com/client/v4/accounts/${PLAYER_CONFIG.video.accountId}/stream/${id}`;


/** See details https://developers.cloudflare.com/api/operations/stream-m-p-4-downloads-create-downloads */
export const getDownloadVideoApi = (id: string) => `https://api.cloudflare.com/client/v4/accounts/${PLAYER_CONFIG.video.accountId}/stream/${id}/downloads`;

const FIREBASE_REGION = "europe-west1"

export const CLOUD_FUNCTIONS_URL = `https://${FIREBASE_REGION}-a4l-messenger.cloudfunctions.net`