
import {initializeApp} from "firebase/app";
import {getDatabase} from 'firebase/database';
import {getStorage} from 'firebase/storage';
import {getFunctions} from 'firebase/functions';


const firebaseConfig = {
    apiKey: "AIzaSyDtEYF6au3mkf8M9qpa1Rmjs5o6-Vt-Oi0",
    authDomain: "a4l-messenger.firebaseapp.com",
    databaseURL: "https://a4l-messenger-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "a4l-messenger",
    storageBucket: "a4l-messenger.appspot.com",
    messagingSenderId: "19287161965",
    appId: "1:19287161965:web:09dcf3252c61530f8c5364",
    measurementId: "G-SHGB7ED5FC"
};

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west1');
