export const WAVESURFER_PLAYER_OPTIONS = {
  height: 128,
  barWidth: 5,
  barGap: 3,
  progressColor: "#7C911F",
  waveColor: "#566639",
  cursorColor: "transparent",
  normalize: true,
}

export const WAVESURFER_RECORDER_OPTIONS = {
  height: 128,
  barWidth: 5,
  barGap: 3,
  progressColor: "#E0E0E1",
  waveColor: "#929293",
  cursorColor: "transparent",
  barHeight: 4,
  normalize: false,
}
