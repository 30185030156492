import React, { FC, memo, PropsWithChildren } from "react"
import MOverlay from "../base/MOverlay"
import MLoader from "../base/MLoader"
import MProgressBar from '../base/MProgressBar';

interface ContentWrapperProps {
  duration?: number | null
  currentTime?: number
  progressBarTransitionDuration?: string
  uploading?: boolean
  wrapperClassName?: string
  showProgressBar?: boolean
}

const ContentWrapper: FC<PropsWithChildren<ContentWrapperProps>> = ({
  duration = 30,
  currentTime = 0,
  progressBarTransitionDuration = "0.1s",
  uploading = false,
  wrapperClassName = "",
  showProgressBar = false,
  children,
}) => {
  return (
    <div
      className={`relative w-full after:content-[""] after:block after:pb-[100%] bg-dark-tertiary rounded-md`}
    >
      <div
        className={`absolute w-full h-full top-1/2 left-0 right-0 transform -translate-y-1/2 ${wrapperClassName}`}
      >
        {children}
      </div>
      {showProgressBar && duration ? (
        <MProgressBar
          completed={Math.round((currentTime * 100) / duration)}
          className="absolute bottom-0 left-0 right-0"
          transitionDuration={progressBarTransitionDuration}
        />
      ) : null}
      <MLoader shown={uploading} label='Ihre Nachricht wird hochgeladen – das kann einen Moment dauern' />
      <MOverlay shown={uploading} />
    </div>
  )
}

export default memo(ContentWrapper)
