export const PLAYER_CONFIG = {
  video: {
    accountId: "2263be4fd25118fa15351ac3d142c66d",
    timeLimit: 30 * 1000,
    constraints: {
      audio: true,
      video: {
        width: 400,
        height: 400,
        aspectRatio: {
          ideal: 1,
        }
      },
      stopStreamsOnStop: true,
      askPermissionsOnMount: true,
      mediaRecorderOptions: {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2000000,
      },
    },
  },
  audio: {
    timeLimit: 30 * 1000,
  },
}
