import React, { FC } from "react"
import MOverlay from "./MOverlay"
import { ReactComponent as LoaderIcon } from "assets/icons/loader.svg"

interface LoaderProps {
  shown?: boolean
  label?: string
  showOverlay?: boolean
}

const MLoader: FC<LoaderProps> = ({ shown, label, showOverlay = true }) => {
  return shown ? (
    <>
      <div className="absolute-center w-full flex items-center justify-center z-50">
        <LoaderIcon color="#fff" width={110} height={110} className="animate-spin-slow" />
        <span className='absolute-x-center max-w-xs w-full text-center text-white top-28'>{label}</span>
      </div>
      <MOverlay shown={showOverlay}/>
    </>
  ) : null
}

export default MLoader
