import React, { FC, useEffect } from "react"
import { useParams } from "react-router-dom"

import { usePlayer } from "context/player.context"
import { ContentTypeEnum, ParamsType } from "types/common.types"
import VideoRecorderContainer from "../video/video-recorder/VideoRecorder.container"
import AudioRecorderContainer from "../audio/audio-recorder/AudioRecorder.container"

const Recorder: FC = () => {
  const { uid } = useParams<ParamsType>()
  const { recorderContentType, setUid } = usePlayer()

  useEffect(() => {
    uid && setUid(uid)
  }, [uid])

  const handler = (event: BeforeUnloadEvent) => {
    event.preventDefault()
    // Chrome requires returnValue to be set
    event.returnValue = ""
  }

  // Show native alert when user tries to leave the page (close tab or reload page)
  useEffect(() => {
    window.addEventListener("beforeunload", handler)
    return () => {
      window.removeEventListener("beforeunload", handler)
    }
  }, [])

  return uid && recorderContentType ? (
    recorderContentType === ContentTypeEnum.Audio ? (
      <AudioRecorderContainer id={uid} />
    ) : (
      <VideoRecorderContainer id={uid} />
    )
  ) : null
}

export default Recorder
