import React, { FC, memo } from "react"

import { ContentTypeEnum } from "types/common.types"
import { usePlayer } from "context/player.context"
import VideoFullscreen from "../video/video-fullscreen/VideoFullscreen.component"
import AudioFullscreen from "../audio/audio-fullscreen/AudioFullscreen.component"
import { useBranding } from "../../context/branding.context"
import { ref, remove } from "firebase/database"
import { database } from "../../firebase"
import { getDomainKey } from "../../utils/get-domain-key"

const EcMessagePreview: FC = () => {
  const { ecMessageId, ecContentType, uid } = usePlayer()
  const { ecTitleText, ecPreviewImage } = useBranding()

  const domainKey = getDomainKey()

  const onEcVideoProcessed = () => {
    if (!ecMessageId) return;

    void remove(ref(
      database,
      `projects/${domainKey}/videos/${uid}/isProcessing`
    ));
  };

  return ecMessageId ? (
    <div className="flex flex-col items-center md:flex-row mb-5">
      <div
        className="relative flex w-full flex-col items-center max-w-md mb-5 bg-beige md:max-h-96 md:overflow-hidden">
        <img
          src={ecPreviewImage}
          width={399}
          height={487}
          className="w-full h-auto md:max-w-2xl"
          alt="preview"
        />
        {ecMessageId ? (
          ecContentType === ContentTypeEnum.Audio ? (
            <AudioFullscreen messageId={ecMessageId} />
          ) : (
            <VideoFullscreen key={ecMessageId} messageId={ecMessageId} onVideoProcessed={onEcVideoProcessed}/>
          )
        ) : null}
      </div>

      <p className="text-center text-3xl font-antipol pt-9 pb-5 px-4">
        {ecTitleText}
      </p>
    </div>
  ) : null
}

export default memo(EcMessagePreview)
