import { useEffect, useState } from "react"
import { MediaPermissionsError, requestMediaPermissions } from "mic-check"

export const usePermissions = (constraints: MediaStreamConstraints, recorderStatus?: string) => {
  const [permissionsAllowed, setPermissionsAllowed] = useState(false)
  const [showDeniedPermissionsPopup, setShowDeniedPermissionsPopup] =
    useState(false)

  // Handle permissions
  useEffect(() => {
    requestMediaPermissions(constraints)
      .then(() => {
        setPermissionsAllowed(true)
        setShowDeniedPermissionsPopup(false)
      })
      .catch((err: MediaPermissionsError) => {
        setPermissionsAllowed(false)
        const { name, message } = err
        setShowDeniedPermissionsPopup(true)
        console.error("Unknown error", name, message)
      })
  }, [])

  useEffect(() => {
    if (recorderStatus === "permission_denied") {
      setShowDeniedPermissionsPopup(true)
    }
  }, [recorderStatus])


  return { permissionsAllowed, showDeniedPermissionsPopup }
};