import React, { FC, useEffect } from "react"
import { useParams } from "react-router-dom"

import { usePlayer } from "context/player.context"
import { ParamsType } from "types/common.types"
import Messenger from "./Messenger.component"
import { BrandingContextProvider } from "../../context/branding.context"

const MessengerContainer: FC = () => {
  const { uid } = useParams<ParamsType>()
  const { setUid } = usePlayer()

  useEffect(() => {
    uid && setUid(uid)
  }, [uid])

  return <BrandingContextProvider>
    <Messenger />
  </BrandingContextProvider>
}

export default MessengerContainer
