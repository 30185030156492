import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useReactMediaRecorder } from "react-media-recorder"
import ReactPlayer from "react-player"
import { usePromiseTracker } from "react-promise-tracker"
import Webcam from "react-webcam"
import { useVisibilityChange } from "@uidotdev/usehooks"

import { PLAYER_CONFIG } from "constants/player.constants"
import { usePermissions } from "hooks/permissions.hook"
import { ContentTypeEnum, PlayerButtonTypeEnum } from "types/common.types"
import { FacingModeEnum, RecordingStatusEnum } from "types/recorder.types"
import { formatTimeValue } from "utils/common.utils"
import RecorderHeader from "components/recorder-header/RecorderHeader.component"
import ContentWrapper from "components/content-wrapper/ContentWrapper.component"
import PlayerButton from "components/shared/PlayerButton.component"
import MCountdown from "components/base/MCountdown"
import RecorderNote from "components/recorder-note/RecorderNote.component"
import PlayerControls from "components/recorder-controls/RecorderControls.component"
import PermissionsDeniedPopup from "components/permissions-denied-popup/PermissionsDeniedPopup.component"
import MOverlay from "components/base/MOverlay"
import { createPortal } from "react-dom"

interface VideoRecorderProps {
  uploadVideo: (blob: Blob) => void
  uploadUrl: string | null
  onClose: () => void
}

const VideoRecorder: FC<VideoRecorderProps> = ({
                                                 uploadVideo,
                                                 uploadUrl,
                                                 onClose,
                                               }) => {
  const [videoTimeoutId, setVideoTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [videoUploadAllowed, setVideoUploadAllowed] = useState<boolean>(false)
  const [recordingStatus, setRecordingStatus] = useState<RecordingStatusEnum>(
    RecordingStatusEnum.NotStarted,
  )
  const [recordingTime, setRecordingTime] = useState<number>(0)
  const [facingMode, setFacingMode] = useState<FacingModeEnum | null>(null)
  const [showSwitchCamera, setShowSwitchCamera] = useState(false)
  const [isCameraReady, setIsCameraReady] = useState(false)

  // Refs
  const videoRecorderRef = useRef<Webcam>(null)
  const videoPlayerRef = useRef<ReactPlayer>(null)

  const constraints = useMemo(
    () => ({
      ...PLAYER_CONFIG.video.constraints,
      video: {
        ...PLAYER_CONFIG.video.constraints.video,
        ...(facingMode && { facingMode: { ideal: facingMode } }),
      },
    }),
    [facingMode],
  )

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
  } = useReactMediaRecorder(constraints)

  const documentVisible = useVisibilityChange()

  const { permissionsAllowed, showDeniedPermissionsPopup } = usePermissions({ audio: true, video: true }, status)
  const { promiseInProgress } = usePromiseTracker()

  const showCountdown = permissionsAllowed && isCameraReady && recordingStatus === RecordingStatusEnum.NotStarted

  // Handlers
  const handleStartRecording = useCallback(() => {
    startRecording()
    setRecordingStatus(RecordingStatusEnum.Recording)
  }, [startRecording])

  const handleStopRecording = useCallback(() => {
    previewStream?.getAudioTracks()[0]?.stop()
    stopRecording()
    setRecordingStatus(RecordingStatusEnum.Stopped)
  }, [stopRecording, previewStream])

  const handleVideoUploadConfirmation = useCallback(() => {
    setVideoUploadAllowed(true)
  }, [])

  const handleTimeChange = useCallback(
    ({ playedSeconds }: any) => {
      setRecordingTime(playedSeconds)
    },
    [],
  )

  const handleStartPlaying = useCallback(() => {
    setRecordingStatus(RecordingStatusEnum.Reviewing)
  }, [])

  const handlePausePlaying = useCallback(() => {
    setRecordingStatus(RecordingStatusEnum.Reviewed)
  }, [])

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((previousValue) =>
      previousValue === FacingModeEnum.User ? FacingModeEnum.Environment : FacingModeEnum.User)
  }, [])

  const handleClickOnClose = useCallback(() => {
    handleStopRecording()
    onClose()
  }, [handleStopRecording, onClose])

  // Effects

  // assign stream from react-media-recorder to Webcam instance
  useEffect(() => {
    if (videoRecorderRef.current && previewStream) {
      videoRecorderRef.current.stream = previewStream
    }
  }, [])

  // Check if devise has 'user' or 'environment' camera
  const handleUserMediaReady = () => {
    setIsCameraReady(true)
    if (facingMode) return

    const availableFacingMode = videoRecorderRef.current?.stream?.getVideoTracks()[0]?.getCapabilities?.()?.facingMode
    if (availableFacingMode?.includes(FacingModeEnum.Environment) || availableFacingMode?.includes(FacingModeEnum.User)) {
      setFacingMode(FacingModeEnum.User)
      setShowSwitchCamera(true)
    }
  }

  // Handle video recording stop
  useEffect(() => {
    if (
      status === "stopped" &&
      mediaBlobUrl &&
      uploadUrl &&
      videoUploadAllowed
    ) {
      // Convert blob URL to File
      fetch(mediaBlobUrl)
        .then((res) => res.blob())
        .then((blob) => {
          uploadVideo(blob)
        })
        .catch((err) => console.error("Error processing video blob", err))
    }
  }, [status, mediaBlobUrl, uploadUrl, videoUploadAllowed])

  // Stop recording after time limit exceeded
  useEffect(() => {
    if (recordingStatus === RecordingStatusEnum.Recording) {
      const timerId = setTimeout(() => {
        handleStopRecording()
      }, PLAYER_CONFIG.video.timeLimit)
      setVideoTimeoutId(timerId)
    }
  }, [recordingStatus])

  useEffect(() => {
    if (status === "stopped" && videoTimeoutId) {
      clearTimeout(videoTimeoutId)
      setVideoTimeoutId(null)
    }
  }, [status, videoTimeoutId])

  useEffect(() => {
    return () => {
      handleStopRecording()
      clearBlobUrl()
      setRecordingStatus(RecordingStatusEnum.NotStarted)
      previewStream?.getTracks().forEach(track => track.stop())
    }
  }, [])

  // stop video when tab or window is not visible
  useEffect(() => {
    if (!documentVisible && recordingStatus === RecordingStatusEnum.Recording) {
      handleStopRecording()
    }
  }, [documentVisible, handleStopRecording])

  return createPortal(
    <div className="fixed top-0 left-0 right-0 h-max-screen bg-beige flex flex-col w-full h-full justify-between overflow-y-auto">
      <RecorderHeader
        contentType={ContentTypeEnum.Video}
        onUpload={handleVideoUploadConfirmation}
        onClose={handleClickOnClose}
        startTimer={recordingStatus === RecordingStatusEnum.Recording}
        showUploadButton={!!mediaBlobUrl}
      />
      <div className="relative flex flex-col mx-auto mb-5 max-w-md w-full h-full gap-4 px-4 md:p-2 z-20">
        {mediaBlobUrl ? (
          <ContentWrapper uploading={promiseInProgress}>
            <div style={{ transform: `scaleX(-1)` }}>
              <ReactPlayer
                ref={videoPlayerRef}
                url={mediaBlobUrl}
                controls={false}
                playing={recordingStatus === RecordingStatusEnum.Reviewing}
                width="100%"
                height="100%"
                onProgress={handleTimeChange}
                progressInterval={500}
                playsinline
                onEnded={() => setRecordingStatus(RecordingStatusEnum.Reviewed)}
              />
            </div>
            {recordingStatus !== RecordingStatusEnum.Reviewing ? (
              <PlayerButton
                type={PlayerButtonTypeEnum.Play}
                onClick={handleStartPlaying}
                className="absolute-center z-10"
                size="lg"
                withShadow
              />
            ) : null}
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <Webcam
              ref={videoRecorderRef}
              audio={true}
              playsInline
              autoPlay
              muted
              videoConstraints={{
                ...constraints.video,
              }}
              onUserMedia={handleUserMediaReady}
              onUserMediaError={(error: unknown) => console.error("User media error: " + error)}
              mirrored
            />
            {showCountdown ? (
              <MCountdown callback={handleStartRecording} />
            ) : null}
          </ContentWrapper>
        )}
        <RecorderNote status={recordingStatus} />
      </div>
      <PlayerControls
        recorderType={ContentTypeEnum.Video}
        onPausePlaying={handlePausePlaying}
        onStopRecording={handleStopRecording}
        onRemoveRecording={handleClickOnClose}
        recordingStatus={recordingStatus}
        currentTime={formatTimeValue(recordingTime)}
        showSwitchCamera={showSwitchCamera}
        handleSwitchCamera={handleSwitchCamera}
      />
      <PermissionsDeniedPopup shown={showDeniedPermissionsPopup} />
      <MOverlay shown={promiseInProgress} />
    </div>, document.body,
  )
}

export default memo(VideoRecorder)
