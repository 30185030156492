import React, { FC, memo } from 'react';
import ProgressBar from "@ramonak/react-progress-bar"

interface MProgressBarProps {
  completed: number
  transitionDuration?: string
  className?: string
  height?: string
}

const MProgressBar: FC<MProgressBarProps> = (props) => {
  return (
    <ProgressBar
      isLabelVisible={false}
      barContainerClassName="bg-action/40"
      height="8px"
      bgColor="#7C911F"
      borderRadius={"0"}
      transitionTimingFunction="linear"
      animateOnRender={true}
      {...props}
    />
  )
}

export default memo(MProgressBar)
