import React, { FC } from "react"
import MPopup from "../base/MPopup"

interface PermissionsDeniedPopupProps {
  shown: boolean
}

const PermissionsDeniedPopup: FC<PermissionsDeniedPopupProps> = ({ shown }) => {
  return (
    <MPopup
      title="Erlauben Sie Mikrofon und Kamera Zugriff"
      description="Aktivieren Sie Ihr Mikrofon und Kamera In Ihren Einstellungen."
      shown={shown}
    />
  )
}

export default PermissionsDeniedPopup
