import React, { FC, ReactNode } from "react"
import MOverlay from "./MOverlay"

interface PopupProps {
  title: string
  description?: string
  icon?: ReactNode
  shown: boolean
}

const MPopup: FC<PopupProps> = ({ title, description, icon, shown }) => {
  return shown ? (
    <>
      <div className="flex flex-col items-center box-border max-w-xs w-4/5 gap-2 fixed-center p-4 bg-white z-50 rounded-md">
        {icon ?? null}
        <p className="font-semibold text-md text-center">{title}</p>
        <p className="text-sm text-center max-w-72 w-full">{description}</p>
      </div>
      <MOverlay shown={shown}></MOverlay>
    </>
  ) : null
}

export default MPopup
