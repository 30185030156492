import React, { FC, memo } from "react"

import {
  IMPRINT_LINK,
  PRIVACY_POLICY_LINK,
  QR_MARKETING_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from "constants/menu.constants"

const MENU_ITEMS = [
  {
    url: TERMS_AND_CONDITIONS_LINK,
    text: "Geschäftsbedingungen",
  },
  {
    url: IMPRINT_LINK,
    text: "Impressum",
  },
  {
    url: PRIVACY_POLICY_LINK,
    text: "Datenschutz",
  },
  {
    url: QR_MARKETING_LINK,
    text: "Powered by QR Marketing",
  },
]

const Footer: FC = () => (
  <div
    className="w-full p-4 flex flex-col md:flex-row gap-x-5 gap-y-3 mb-8 text-lg items-center justify-center text-center">
    {
      MENU_ITEMS.map(({url, text}, index) => (
        <a
          key={index}
          target="_blank"
          rel="noreferrer"
          href={url}
          className="inline-block w-fit h-full hover:underline"
        >
          {text}
        </a>
      ))
    }
  </div>
)

export default memo(Footer)
