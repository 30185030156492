import React, { FC, memo, useMemo } from "react"

import { ContentTypeEnum, PlayerButtonTypeEnum } from "types/common.types"

// assets
import { ReactComponent as ReplayIcon } from "assets/icons/replay-icon.svg"
import { ReactComponent as StopIcon } from "assets/icons/stop-icon.svg"
import { ReactComponent as PauseIcon } from "assets/icons/pause-icon.svg"
import { ReactComponent as PlayIcon } from "assets/icons/play-icon.svg"
import { ReactComponent as CameraIcon } from "assets/icons/camera-icon.svg"
import { ReactComponent as MicIcon } from "assets/icons/mic-icon.svg"
import cn from "classnames"

interface PlayerButtonProps {
  type?: PlayerButtonTypeEnum;
  color?: string;
  className?: string;
  iconClassName?: string;
  outlined?: boolean;
  size?: "sm" | "md" | "lg"
  recorderType?: ContentTypeEnum;
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  withShadow?: boolean;
}

const PlayerButton: FC<PlayerButtonProps> = ({
                                               type = PlayerButtonTypeEnum.Play,
                                               color,
                                               className,
                                               iconClassName,
                                               outlined = false,
                                               size = "sm",
                                               recorderType,
                                               onClick,
                                               label,
                                               disabled = false,
                                               withShadow = false,
                                             }) => {
  const DEFAULT_COLOR = "#fff"

  const sizeClassNames = {
    icon: {
      sm: "w-5 h-5",
      md: "w-9 h-9",
      lg: "w-11 h-11",
    },
    button: {
      sm: "w-12 h-12",
      md: "w-18 h-18",
      lg: "w-20 h-20",
    },
  }

  const iconProps = useMemo(
    () => ({
      className: cn(iconClassName, sizeClassNames.icon[size]),
      color: color ?? DEFAULT_COLOR,
    }),
    [iconClassName, color],
  )

  const icon = useMemo(() => {
    switch (type) {
      case PlayerButtonTypeEnum.Replay:
        return <ReplayIcon {...iconProps} />
      case PlayerButtonTypeEnum.Stop:
        return <StopIcon  {...iconProps} />
      case PlayerButtonTypeEnum.Pause:
        return <PauseIcon {...iconProps} />
      case PlayerButtonTypeEnum.Record:
        return recorderType === ContentTypeEnum.Video ? <CameraIcon {...iconProps} /> : <MicIcon {...iconProps} />
      default:
        return <PlayIcon {...iconProps} />
    }
  }, [type, iconProps])

  return (
    <button
      className="group w-36 flex flex-col justify-center items-center gap-4"
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={cn("flex justify-center items-center bg-red group-hover:bg-dark-red rounded-full",
          className,
          sizeClassNames.button[size], {
            "outline outline-1 outline-offset-6 outline-red": outlined,
            "opacity-40": disabled,
            "shadow-base": withShadow,
          })}
      >
        {icon}
      </div>
      {label ? (
        <p className="w-full text-center text-xs uppercase font-semibold tracking-widest">
          {label}
        </p>
      ) : false}
    </button>
  )
}

export default memo(PlayerButton)
