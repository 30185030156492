export enum ButtonTypeEnum {
  Icon = "icon",
  Text = "text",
}

export enum PlayerButtonTypeEnum {
  Play = "play",
  Stop = "stop",
  Pause = "pause",
  Replay = "replay",
  Record = "record",
}

export enum ContentTypeEnum {
  Audio = "audio",
  Video = "video",
}

export type ParamsType = {
  uid: string
}
