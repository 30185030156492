import React, { FC, memo } from "react"
import { PlayerButtonTypeEnum } from "types/common.types"
import PlayerButton from "../shared/PlayerButton.component"

interface PlayerControlsProps {
  isPlaying: boolean
  isFinished: boolean
  onPlay: () => void
  onPause: () => void
  onReplay: () => void
}

const PlayerControls: FC<PlayerControlsProps> = ({
  isPlaying,
  isFinished,
  onPlay,
  onPause,
  onReplay,
}) => {
  return !isPlaying && !isFinished ? (
    <PlayerButton
      type={PlayerButtonTypeEnum.Play}
      onClick={onPlay}
      className="absolute-x-center bottom-4"
    />
  ) : isFinished ? (
    <PlayerButton
      type={PlayerButtonTypeEnum.Replay}
      onClick={onReplay}
      className="absolute-x-center bottom-4"
    />
  ) : (
    <PlayerButton
      type={PlayerButtonTypeEnum.Pause}
      onClick={onPause}
      className="absolute-x-center bottom-4"
    />
  )
}

export default memo(PlayerControls)
