import React, { useState, useEffect, useCallback, FC } from "react"
import { httpsCallable } from "firebase/functions"
import { ref, set, get, remove } from "firebase/database"
import { useNavigate } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"

import { functions, database } from "../../../firebase"
import VideoRecorder from "./VideoRecorder.component"
import { usePlayer } from "context/player.context"
import { getDomainKey } from "../../../utils/get-domain-key"

interface VideoRecorderContainerProps {
  id: string
}

const VideoRecorderContainer: FC<VideoRecorderContainerProps> = ({ id }) => {
  const [uploadUrl, setUploadUrl] = useState<string | null>(null)
  const [videoUID, setVideoUID] = useState<string | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const domainKey = getDomainKey()

  const navigate = useNavigate()
  const { setIsMessageUploaded } = usePlayer()

  const removePreviousAudioMessage = () => {
    const videoRef = ref(database, `projects/${domainKey}/audios/${id}`)
    void trackPromise(remove(videoRef))
  }

  // Get upload URL from Firebase Cloud Function
  useEffect(() => {
    const getUploadUrl = async () => {
      const generateUploadUrl = httpsCallable(functions, "generateUploadUrl")
      const response = await generateUploadUrl({ id: id })
      // @ts-ignore
      setUploadUrl(response.data.uploadURL)
      // @ts-ignore
      setVideoUID(response.data.uid)
    }

    getUploadUrl()
      .catch((err) => console.error("Error generating upload url: ", err))
  }, [])

  useEffect(() => {
    // TODO: replace with toast
    if (uploadError) {
      console.error("Video upload error: ", uploadError)
    }
  }, [uploadError])

  const uploadVideo = useCallback(
    async (file: File) => {
      removePreviousAudioMessage()

      const formData = new FormData()
      formData.append("file", file)

      setUploadError(null)

      // Upload video to Cloudflare
      const response = await fetch(uploadUrl!, {
        method: "POST",
        body: formData,
      })

      if (response.ok) {
        const videoRef = ref(database, `projects/${domainKey}/videos/${id}`)
        // get previous UID from Firebase Realtime Database
        const previousUIDp = await get(videoRef)
        const previousUID = (previousUIDp.val() || { uid: undefined }).uid

        const videoInfo = { uid: videoUID, url: uploadUrl, isProcessing: true }

        // Store video information in Firebase Realtime Database
        await set(videoRef, videoInfo)

        // Delete previous video from Cloudflare
        if (previousUID) {
          const deleteVideo = httpsCallable(functions, "deleteVideo")
          try {
            await deleteVideo({ uid: previousUID })
          } catch (err) {
            console.error("Error deleting video", err)
          }
        }
      } else {
        console.error("Upload failed.")
        setUploadError(
          "Upload failed. " + response.status + " " + response.statusText,
        )
      }
    },
    [uploadUrl, videoUID, id],
  )

  const handleUploadVideo = useCallback(
    async (blob: Blob) => {
      const file = new File([blob], "video.mp4", { type: blob.type })
      void trackPromise(
        uploadVideo(file)
          .then(() => {
            setIsMessageUploaded(true)
            navigate(`/${id}`)
          })
          .catch((err) => console.error("Video upload error", err)),
      )
    },
    [uploadVideo],
  )

  const handleClickOnClose = useCallback(() => {
    const confirmed = confirm(
      "Möchten Sie diese Seite wirklich verlassen? Wenn Sie dieses Seite schließen, geht Ihre gesamte Aufnahme verloren.",
    )
    confirmed && navigate(`/${id}`)
  }, [navigate])

  return (
    <VideoRecorder
      uploadVideo={handleUploadVideo}
      uploadUrl={uploadUrl}
      onClose={handleClickOnClose}
    />
  )
}

export default VideoRecorderContainer
