import React, { FC } from "react"
import MPopup from "../base/MPopup"

// assets
import { ReactComponent as CheckCircleIcon } from "assets/icons/check-circle-icon.svg"

interface MessageUploadedPopupProps {
  shown: boolean
}

const MessageUploadedPopup: FC<MessageUploadedPopupProps> = ({ shown }) => {
  return (
    <MPopup
      title="Glückwunsch"
      description="Ihre Aufnahme war erfolgreich. Ihre Nachricht läuft in 60 Tagen ab."
      icon={<CheckCircleIcon className="w-5 h-5 text-red" />}
      shown={shown}
    />
  )
}

export default MessageUploadedPopup
