import React, { FC } from 'react';
import { useTimer } from 'hooks/timer.hook';

interface MCountdownProps {
  count?: number
  callback?: () => void
}

const MCountdown: FC<MCountdownProps> = ({ count = 3, callback}) => {
  const {timer, isStopped} = useTimer(count, callback)

  return isStopped ? null : (
    <div className='absolute left-0 top-0 flex w-full h-full justify-center items-center'>
      <div className={`absolute left-0 top-0 w-full h-full flex-1 backdrop-blur-xs`} />
      <span className="text-white text-center font-bold text-8xl z-10">{timer}</span>
    </div>
  )
}

export default MCountdown
