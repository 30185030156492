import React, { FC } from "react"

import { ContentTypeEnum } from "types/common.types"
import { usePlayer } from "context/player.context"
import { useBranding } from "../../context/branding.context"
import VideoFullscreen from "../video/video-fullscreen/VideoFullscreen.component"
import AudioFullscreen from "../audio/audio-fullscreen/AudioFullscreen.component"

const BcMessagePreview: FC = () => {
  const { bcMessageId, bcContentType } = usePlayer()
  const { bcPreviewImage } = useBranding()

  return bcMessageId ? (
    <div className="relative w-fit">
      <img
        alt="preview"
        src={bcPreviewImage}
        width={390}
        height={363}
        className="w-full h-auto"
      />
      {bcContentType === ContentTypeEnum.Audio ? (
        <AudioFullscreen messageId={bcMessageId} />
      ) : (
        <VideoFullscreen messageId={bcMessageId} isBcMessage />
      )}
    </div>
  ) : null
}

export default BcMessagePreview
