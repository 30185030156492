import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { usePromiseTracker } from "react-promise-tracker"

import { ContentTypeEnum, PlayerButtonTypeEnum } from "types/common.types"
import { RecordingStatusEnum } from "types/recorder.types"
import { useWavesurfer } from "hooks/wavesurfer.hook"
import { usePermissions } from "hooks/permissions.hook"
import { formatTimeValue } from "utils/common.utils"
import { WAVESURFER_PLAYER_OPTIONS, WAVESURFER_RECORDER_OPTIONS } from "constants/wavesurfer.constants"
import { PLAYER_CONFIG } from "constants/player.constants"
import ContentWrapper from "components/content-wrapper/ContentWrapper.component"
import MCountdown from "components/base/MCountdown"
import PlayerButton from "components/shared/PlayerButton.component"
import PlayerControls from "components/recorder-controls/RecorderControls.component"
import RecorderNote from "components/recorder-note/RecorderNote.component"
import RecorderHeader from "components/recorder-header/RecorderHeader.component"
import PermissionsDeniedPopup from "components/permissions-denied-popup/PermissionsDeniedPopup.component"
import MOverlay from "components/base/MOverlay"

// assets
import dummyAudioWave from "assets/images/dummy-audio-wave.webp"
import cn from "classnames"
import { createPortal } from "react-dom"
import { useVisibilityChange } from "@uidotdev/usehooks"

interface AudioRecorderProps {
  onUpload?: (blob: Blob) => void
  onClose: () => void
}

const AudioRecorder: FC<AudioRecorderProps> = ({ onUpload, onClose }) => {
  const [recordedUrl, setRecordedUrl] = useState<string>()
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null)
  const [recordingStatus, setRecordingStatus] = useState(
    RecordingStatusEnum.NotStarted,
  )
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [recordingTime, setRecordingTime] = useState<number>(0)

  // Refs
  const waveRecorderContainerRef = useRef<HTMLDivElement>(null)
  const wavePlayerContainerRef = useRef<HTMLDivElement>(null)

  const { promiseInProgress } = usePromiseTracker()

  // wavesurfer instance for recording
  const audioWaveRecorderOptions = useMemo(
    () => ({
      ...WAVESURFER_RECORDER_OPTIONS,
    }),
    [],
  )
  const { recorder, wavesurfer: recorderWavesurfer } = useWavesurfer(
    waveRecorderContainerRef,
    audioWaveRecorderOptions,
  )

  const documentVisible = useVisibilityChange();

  // wavesurfer instance for playing
  const audioWavePlayerOptions = useMemo(
    () => ({
      ...WAVESURFER_PLAYER_OPTIONS,
      url: recordedUrl,
    }),
    [recordedUrl],
  )
  const {
    wavesurfer: playerWavesurfer,
    isPlaying,
    isFinished,
    currentTime,
    duration,
  } = useWavesurfer(wavePlayerContainerRef, audioWavePlayerOptions)

  const { permissionsAllowed, showDeniedPermissionsPopup } = usePermissions({ audio: true, video: false })

  // Handlers
  const handleStartRecording = useCallback(() => {
    setRecordingStatus(RecordingStatusEnum.Recording)
    recorder?.startRecording()
  }, [recorder])

  const handleStopRecording = useCallback(() => {
    recorder?.stopRecording()
  }, [recorder])

  const handleClickOnUpload = useCallback(() => {
    recordingBlob && onUpload?.(recordingBlob)
  }, [onUpload, recordingBlob])

  const handleStartPlaying = useCallback(() => {
    playerWavesurfer?.play()
    setRecordingStatus(RecordingStatusEnum.Reviewing)
  }, [playerWavesurfer])

  const handlePausePlaying = useCallback(() => {
    playerWavesurfer?.pause()
    setRecordingStatus(RecordingStatusEnum.Reviewed)
  }, [playerWavesurfer])

  // Effects
  useEffect(() => {
    isFinished && setRecordingStatus(RecordingStatusEnum.Reviewed)
  }, [isFinished])

  // Stop recording after time limit exceeded
  useEffect(() => {
    if (recordingStatus === RecordingStatusEnum.Recording) {
      const timeoutId = setTimeout(() => {
        handleStopRecording()
      }, PLAYER_CONFIG.audio.timeLimit)
      setTimeoutId(timeoutId)
    }
  }, [recordingStatus, handleStopRecording])

  // Handle end of recording
  useEffect(() => {
    recorder?.on("record-end", (blob: Blob) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      setTimeoutId(null)

      const recordedUrl = URL.createObjectURL(blob)
      setRecordedUrl(recordedUrl)
      setRecordingBlob(blob)
      setRecordingStatus(RecordingStatusEnum.Stopped)
      recorderWavesurfer.destroy()
    })
  }, [recorder])

  const handleTimeChange = useCallback(
    (time: number) => setRecordingTime(time),
    [],
  )

  const handleClickOnClose = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    handleStopRecording()
    onClose()
  }, [handleStopRecording, onClose])

  useEffect(() => {
    if (!documentVisible && recordingStatus === RecordingStatusEnum.Recording) {
      handleStopRecording()
    }
  }, [documentVisible, handleStopRecording])

  return createPortal(
    <div className="fixed top-0 left-0 right-0 h-max-screen bg-beige flex flex-col w-full h-full justify-between overflow-y-auto">
      <RecorderHeader
        contentType={ContentTypeEnum.Audio}
        onUpload={handleClickOnUpload}
        onClose={handleClickOnClose}
        startTimer={recordingStatus === RecordingStatusEnum.Recording}
        showUploadButton={!!recordingBlob}
        onTimeChange={handleTimeChange}
      />
      <div className="relative flex flex-col mx-auto mb-5 max-w-md w-full h-full gap-4 px-4 md:p-2 z-20">
        {recordedUrl ? (
          <ContentWrapper
            duration={duration}
            currentTime={currentTime}
            uploading={promiseInProgress}
            wrapperClassName="p-11 flex flex-col items-center justify-center"
          >
            <div ref={wavePlayerContainerRef} className="max-h-32 h-fit w-full" />
            {!isPlaying ? (
              <PlayerButton
                type={PlayerButtonTypeEnum.Play}
                onClick={handleStartPlaying}
                className="absolute-center z-10"
                size="lg"
                withShadow
              />
            ) : null}
          </ContentWrapper>
        ) : (
          <ContentWrapper
            duration={PLAYER_CONFIG.audio.timeLimit / 1000}
            currentTime={PLAYER_CONFIG.audio.timeLimit / 1000 - recordingTime}
            progressBarTransitionDuration="1s"
            wrapperClassName="p-11 flex items-center"
          >
            <div
              ref={waveRecorderContainerRef}
              className={cn("max-h-32 h-fit w-full", {
                  hidden: recordedUrl,
                },
              )}
            />
            {recordingStatus !== RecordingStatusEnum.Recording ? (
              <img
                alt="audio wave"
                src={dummyAudioWave}
                width={344}
                height={120}
                className="absolute-center p-11"
              />
            ) : null}
            {permissionsAllowed && recordingStatus === RecordingStatusEnum.NotStarted ? (
              <MCountdown callback={handleStartRecording} />
            ) : null}
          </ContentWrapper>
        )}
        <RecorderNote status={recordingStatus} />
      </div>

      <PlayerControls
        recorderType={ContentTypeEnum.Audio}
        onPausePlaying={handlePausePlaying}
        onStopRecording={handleStopRecording}
        onRemoveRecording={handleClickOnClose}
        recordingStatus={recordingStatus}
        currentTime={formatTimeValue(currentTime)}
      />
      <PermissionsDeniedPopup shown={showDeniedPermissionsPopup} />
      <MOverlay shown={promiseInProgress} />
    </div>, document.body
  )
}

export default memo(AudioRecorder)
