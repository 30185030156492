import { RefObject, useEffect } from "react"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"

export const useBodyScrollLock = (modalRef: RefObject<HTMLElement>) => {
  useEffect(() => {
    modalRef.current && disableBodyScroll(modalRef.current)

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])
}
