import React, { FC } from "react"

import { usePlayer } from "context/player.context"
import { useBranding } from "context/branding.context"
import NewMessagePopup from "../new-record-popup/NewRecordPopup.component"
import BcMessagePreview from "../bc-message-preview/BcMessagePreview.component"
import EcMessagePreview from "../ec-message-preview/EcMessagePreview.component"
import MessageUploadedPopup from "../message-uploaded-popup/MessageUploadedPopup.component"
import BrandInfo from "../shared/BrandInfo.component"
import MButton from "../base/MButton"
import RecordTypeSwitch from "../record-type-switch/RecordTypeSwitch.component"
import Footer from "../shared/Footer.component"

import { ReactComponent as CameraIcon } from "assets/icons/camera-icon.svg"

const NewRecordingButton = () => {
  const { newRecordingButtonText } = useBranding()
  const { setShowNewMessagePopup } = usePlayer()

  const handleClickOnRecordNewMessage = () => {
    setShowNewMessagePopup(true)
  }

  return (
    <MButton buttonClassName="self-center" onClick={handleClickOnRecordNewMessage}>
      <CameraIcon className="text-white" width={24} height={24} />
      {newRecordingButtonText}
    </MButton>
  )
}


const Messenger: FC = () => {
  const {
    isMessageUploaded,
    ecMessageId,
    showNewMessagePopup,
  } = usePlayer()

  const { bcTitleText, bcDescriptionText, bcShortDescription } = useBranding()

  return (
    <div className="relative w-full min-h-screen h-full mx-auto flex flex-col bg-white md:max-w-4xl shadow-lg">
      <div className="md:pt-28 md:px-16 pb-6">
        {ecMessageId ? (
          <>
            <EcMessagePreview />
            <p className="text-center text-sm font-bold uppercase mb-5">
              {bcShortDescription}
            </p>
          </>
        ) : null}
        <div className="flex flex-col items-center gap-14 mb-5 md:gap-5 md:flex-row">
          <BcMessagePreview />

          {!ecMessageId ? (
            <div className="flex flex-col justify-center gap-5 px-4 md:max-w-xs md:px-0 text-center">
              <h1 className="text-3xl font-antipol">{bcTitleText}</h1>
              <p className="text-base">
                {bcDescriptionText}
              </p>
              <NewRecordingButton />
            </div>
          ) : false}
        </div>


        <div className="flex-1 flex flex-col pt-5 pb-6 px-6 md:px-9 mx-4 md:mx-0 mb-12 bg-beige gap-5 items-center text-center">
          <h1 className="font-antipol text-3xl">Teile dein Glück</h1>
          <p>
            Deine persönliche Glücksbotschaft wird
            gespeichert und ist über den QR Code auf dem Vorderetikett hinterlegt. Teste es!
          </p>

          <RecordTypeSwitch />
        </div>


        <div className="flex mx-auto flex-col items-center max-w-2xl p-4 text-center mb-14 md:mb-10">
          <p className="text-2xl font-antipol italic mb-2">
            »Glück ist das einzige,
            das sich verdoppelt, wenn man es teilt.«
          </p>
          <p className="mb-5 text-xs font-semibold uppercase">
            Albert schweitzer
          </p>
          <p className="mb-4">
            Wir freuen uns über die tolle Kooperation zwischen der Winzergenossenschaft Achkarren und Rossmann. Das
            schmeckt man in unserem Wein, mit dem wir ein Stück unseres Glücks nun an dich weitergeben – denn Glück
            wird noch größer, wenn man es teilt.
          </p>
          <p className="mb-5">
            Probiere es gleich aus und teile dein Glück!
          </p>
          <NewRecordingButton />
        </div>

        <BrandInfo />
      </div>
      {showNewMessagePopup ? <NewMessagePopup /> : false}
      <MessageUploadedPopup shown={!!isMessageUploaded} />

      <Footer />
    </div>
  )
}

export default Messenger
