import React, { FC } from "react"
import PlayerButton from "../shared/PlayerButton.component"
import { ContentTypeEnum, PlayerButtonTypeEnum } from "../../types/common.types"
import { useNavigate } from "react-router-dom"
import { usePlayer } from "../../context/player.context"

const RecordTypeSwitch: FC = () => {
  const { setShowNewMessagePopup, setRecorderContentType, uid } = usePlayer()

  const navigate = useNavigate()

  const openRecorder = (contentType: ContentTypeEnum) => {
    setRecorderContentType(contentType)
    setShowNewMessagePopup(false)
    navigate("recorder")
  }

  return (
    <>
      <div className="flex justify-center gap-2 mb-3">
        <PlayerButton type={PlayerButtonTypeEnum.Record}
                      iconClassName="!w-11 !h-11" size="md"
                      outlined
                      recorderType={ContentTypeEnum.Video}
                      label="Videobotschaft aufnehmen"
                      onClick={() => openRecorder(ContentTypeEnum.Video)}
                      disabled={!uid}
        />
        <PlayerButton type={PlayerButtonTypeEnum.Record}
                      iconClassName="!w-11 !h-11"
                      size="md"
                      outlined
                      recorderType={ContentTypeEnum.Audio}
                      label="Sprachnachricht aufnehmen"
                      onClick={() => openRecorder(ContentTypeEnum.Audio)}
                      disabled={!uid}
        />
      </div>

      {!uid ? (
        <p className="text-sm pt-3">
          Der Link ist ungültig. Überprüfe, ob du einen gültigen Link verwendest.
        </p>
      ) : false}
    </>
  )
}

export default RecordTypeSwitch
