import React, { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"
import { get, ref, remove, set } from "firebase/database"
import { deleteObject, ref as storageRef } from "firebase/storage"
import axios from "axios"

import AudioRecorder from "./AudioRecorder.component"
import { database, storage } from "../../../firebase"
import { usePlayer } from "context/player.context"
import { CLOUD_FUNCTIONS_URL } from "constants/api.constants"
import { getDomainKey } from "../../../utils/get-domain-key"

interface AudioRecorderContainerProps {
  id: string
}

const AudioRecorderContainer: FC<AudioRecorderContainerProps> = ({ id }) => {
  const navigate = useNavigate()
  const domainKey = getDomainKey()

  const { setIsMessageUploaded } = usePlayer()

  const removePreviousVideoMessage = () => {
    const videoRef = ref(database, `projects/${domainKey}/videos/${id}`)
    void trackPromise(remove(videoRef))
  }

  const handleUpload = useCallback(
    async (blob: Blob) => {
      const audioRef = ref(database, `projects/${domainKey}/audios/${id}`)

      removePreviousVideoMessage()

      if (!blob) return

      const formData = new FormData()
      formData.append("file", blob)

      void trackPromise(axios.post(`${CLOUD_FUNCTIONS_URL}/uploadAudioAsMp3`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(async (data) => {
            const prevAudioIdP = await get(audioRef)
            const prevAudioId = prevAudioIdP ? prevAudioIdP.val() : null
            await set(audioRef, data.data.uid)

            if (prevAudioId) {
              const prevAudioRef = storageRef(storage, `audio/${prevAudioId}.mp3`)
              await deleteObject(prevAudioRef)
            }

            setIsMessageUploaded(true)
            navigate(`/${id}`)
        }),
      )
    },
    [id],
  )

  const handleClickOnClose = () => {
    const confirmed = confirm(
      "Möchten Sie diese Seite wirklich verlassen? Wenn Sie dieses Seite schließen, geht Ihre gesamte Aufnahme verloren.",
    )
    confirmed && navigate(`/${id}`)
  }

  return <AudioRecorder onClose={handleClickOnClose} onUpload={handleUpload} />
}

export default AudioRecorderContainer
