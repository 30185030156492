import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { onValue, ref } from "firebase/database"
import { database } from "../firebase"
import { ContentTypeEnum } from "types/common.types"
import { getDomainKey } from "../utils/get-domain-key"
import { VideoMessageDetails } from "../types/video-details.types"

interface IPlayerContext {
  uid?: string
  setUid: Dispatch<SetStateAction<string>>
  isMessageUploaded: boolean | null
  setIsMessageUploaded: Dispatch<SetStateAction<boolean | null>>
  bcMessageId?: string
  ecMessageId?: string
  ecContentType?: string | null
  bcContentType?: string | null
  showNewMessagePopup: boolean
  setShowNewMessagePopup: Dispatch<SetStateAction<boolean>>
  recorderContentType?: ContentTypeEnum | null
  setRecorderContentType: Dispatch<SetStateAction<ContentTypeEnum | null>>
  isEcVideoProcessing: boolean
}

const PlayerContext = createContext<IPlayerContext | undefined>(undefined)

export const usePlayer = () => {
  const context = useContext(PlayerContext)
  if (context === undefined) {
    throw new Error("usePlayer must be used within a PlayerContextProvider")
  }
  return context
}

export const PlayerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMessageUploaded, setIsMessageUploaded] = useState<boolean | null>(
    null,
  )
  const [ecMessageId, setEcMessageId] = useState<string>()
  const [bcMessageId, setBcMessageId] = useState<string>()
  const [ecContentType, setEcContentType] = useState<ContentTypeEnum | null>(
    null,
  )
  const [bcContentType, setBcContentType] = useState<ContentTypeEnum | null>(
    null,
  )
  const [showNewMessagePopup, setShowNewMessagePopup] = useState<boolean>(false)
  const [recorderContentType, setRecorderContentType] =
    useState<ContentTypeEnum | null>(null)
  const [uid, setUid] = useState<string>("")
  const [isEcVideoProcessing, setIsEcVideoProcessing] = useState(true)

  const domainKey = getDomainKey()

  useEffect(() => {
    // Get id of EC video message
    onValue(
      ref(database, `projects/${domainKey}/videos/${uid}`),
      (snapshot) => {
        const video = snapshot.val() as VideoMessageDetails
        if (video) {
          setEcMessageId(video.uid)
          setEcContentType(ContentTypeEnum.Video)
          setIsEcVideoProcessing(!!video.isProcessing)
        }
      },
    )

    // Get id of BC video message
    onValue(ref(database, `projects/${domainKey}/defaultVideo`), (snapshot) => {
      const videoId = snapshot.val()
      if (videoId) {
        setBcMessageId(videoId)
        setBcContentType(ContentTypeEnum.Video)
      }
    })
  }, [uid])

  useEffect(() => {
    // Get id of EC audio message
    onValue(
      ref(database, `projects/${domainKey}/audios/${uid}`),
      (snapshot) => {
        const audioId = snapshot.val()
        // get public URL of the file
        if (audioId) {
          setEcMessageId(audioId)
          setEcContentType(ContentTypeEnum.Audio)
        }
      },
    )

    // Get id of BC audio message
    onValue(
      ref(database, `projects/${domainKey}/defaultAudio/${uid}`),
      (snapshot) => {
        const audioId = snapshot.val()
        // get public URL of the file
        if (audioId) {
          setBcMessageId(audioId)
          setBcContentType(ContentTypeEnum.Audio)
        }
      },
    )
  }, [uid])

  // Reset video uploaded state after 3 seconds
  useEffect(() => {
    isMessageUploaded === true &&
      setTimeout(() => setIsMessageUploaded(null), 3000)
  }, [isMessageUploaded])

  return (
    <PlayerContext.Provider
      value={{
        uid,
        setUid,
        isMessageUploaded,
        setIsMessageUploaded,
        bcMessageId,
        ecMessageId,
        ecContentType,
        bcContentType,
        showNewMessagePopup,
        setShowNewMessagePopup,
        recorderContentType,
        setRecorderContentType,
        isEcVideoProcessing,
      }}
    >
      {children}
    </PlayerContext.Provider>
  )
}
