import React, { createContext, FC, PropsWithChildren, useContext } from "react"

// assets
import bcPreviewImage from "assets/images/preview.webp"
import ecPreviewImage from "assets/images/wine-graphics.svg"

interface IBrandingContext {
  newRecordingButtonText: string
  popupDescriptionText?: string
  bcTitleText: string
  bcDescriptionText?: string
  bcShortDescription: string
  ecTitleText: string
  bcPreviewImage: string
  ecPreviewImage: string
}

const BrandingContext = createContext<IBrandingContext | undefined>(undefined)

export const useBranding = () => {
  const context = useContext(BrandingContext)
  if (context === undefined) {
    throw new Error("useBranding must be used within a BrandingContextProvider")
  }
  return context
}

export const BrandingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return <BrandingContext.Provider value={{
    newRecordingButtonText: "Glück Teilen",
    popupDescriptionText: "Deine persönliche Glücksbotschaft wird gespeichert und ist über den QR-Code auf dem Vorderetikett hinterlegt.",
    bcTitleText: "Was Glück für uns bedeutet",
    bcDescriptionText: "Erzähle von deinem Glück und nimm für deine Liebe/n eine persönliche Glücksbotschaft als Video oder Sprachnachricht auf:",
    ecTitleText: "Hier ist eine Glücksbotschaft für dich!",
    bcShortDescription: "WAS GLÜCK FÜR UNS BEDEUTET:",
    bcPreviewImage,
    ecPreviewImage,
  }}>
    {children}
  </BrandingContext.Provider>
}
