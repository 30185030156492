import * as React from "react"

import { BrowserRouter, Routes, Route } from "react-router-dom"
import Recorder from "./components/recorder/Recorder.component"
import { PlayerContextProvider } from "./context/player.context"
import MessengerContainer from "./components/messenger/Messenger.container"

const App: React.FC = () => {
  return (
    <PlayerContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/:uid" element={<MessengerContainer />} />
          <Route path="/:uid/recorder" element={<Recorder />} />
          <Route path="/" element={<MessengerContainer />} />
        </Routes>
      </BrowserRouter>
    </PlayerContextProvider>
  )
}

export default App
