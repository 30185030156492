import React, { FC, useEffect, useState } from "react"

import { ContentTypeEnum } from "types/common.types"
import { useTimer } from "hooks/timer.hook"
import { formatTimeValue } from "utils/common.utils"

// assets
import { ReactComponent as RecordIcon } from "assets/icons/record-icon.svg"
import { ReactComponent as MicIcon } from "assets/icons/mic-icon.svg"
import { ReactComponent as CameraIcon } from "assets/icons/camera-icon.svg"
import cn from "classnames"

interface TimerProps {
  type: ContentTypeEnum
  seconds?: number
  start?: boolean
  onStop?: () => void
  onTimeChange?: (time: number) => void
}

const Timer: FC<TimerProps> = ({
  type,
  seconds = 30,
  start,
  onStop,
  onTimeChange,
}) => {
  const ICON_COLOR = "#a3a3a5"

  const [warning, setWarning] = useState(false)
  const [formattedValue, setFormattedValue] = useState<string>()
  const { timer, isStopped } = useTimer(seconds, onStop, start)

  useEffect(() => {
    onTimeChange?.(timer)
    setFormattedValue(formatTimeValue(timer))
    if (timer <= 5) {
      setWarning(true)
    }
  }, [timer])

  return !isStopped ? (
    <div className="absolute-center flex items-center gap-1 top-4 h-5">
      <RecordIcon className="shrink-0 mx-1" />
      <p
        className={cn("text-center w-12 text-lg mt-1", {
          "text-error-400": warning,
          "text-dark-grey": !warning,
          "opacity-50": !start
        })}
      >
        {formattedValue}
      </p>
      {ContentTypeEnum.Audio === type ? (
        <MicIcon color={ICON_COLOR} className="w-5 h-5 shrink-0" />
      ) : (
        <CameraIcon color={ICON_COLOR} className="w-5 h-5 shrink-0"/>
      )}
    </div>
  ) : null
}

export default Timer
