// TODO: update to be able use more than 59 seconds and move to utils

import { AxiosResponse } from "axios"

export const round = (value: number) => Math.round(value * 100) / 100
export const formatTimeValue = (value: number) =>
  `00:${Math.round(value).toString().padStart(2, "0")}`

export const poll = async function (
  fn: () => Promise<AxiosResponse<any>> | null,
  fnCondition: (response: AxiosResponse<any>) => boolean,
  ms: number = 1000,
) {
  if (!fn) return

  let result = await fn()
  while (fnCondition(result!)) {
    await wait(ms)
    result = await fn()
  }
  return result
}

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
