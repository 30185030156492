import React, { FC, memo, useMemo } from "react"

import { RecordingStatusEnum } from "types/recorder.types"
import {
  ButtonTypeEnum, ContentTypeEnum,
  PlayerButtonTypeEnum,
} from "types/common.types"
import PlayerButton from "../shared/PlayerButton.component"
import MButton from "../base/MButton"

// assets
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg"
import { ReactComponent as SwitchCameraIcon } from "assets/icons/switch-camera-icon.svg"

interface RecorderControlsProps {
  recorderType: ContentTypeEnum,
  onPausePlaying: () => void
  onStopRecording: () => void
  onRemoveRecording: () => void
  recordingStatus: RecordingStatusEnum
  currentTime: string
  showSwitchCamera?: boolean
  handleSwitchCamera?: () => void
}

const RecorderControls: FC<RecorderControlsProps> = ({
                                                       onPausePlaying,
                                                       onStopRecording,
                                                       onRemoveRecording,
                                                       recordingStatus,
                                                       currentTime,
                                                       showSwitchCamera = false,
                                                       handleSwitchCamera,
                                                     }) => {
  const RECORDED_STATUSES = [
    RecordingStatusEnum.Reviewing,
    RecordingStatusEnum.Reviewed,
    RecordingStatusEnum.Stopped,
  ]

  const isRecorded = useMemo(
    () => RECORDED_STATUSES.includes(recordingStatus),
    [recordingStatus],
  )

  const controlButton = useMemo(() => {
    switch (recordingStatus) {
      case RecordingStatusEnum.Recording:
        return (
          <PlayerButton
            type={PlayerButtonTypeEnum.Stop}
            onClick={onStopRecording}
          />
        )
      case RecordingStatusEnum.Reviewing :
        return (
          <PlayerButton
            type={PlayerButtonTypeEnum.Pause}
            onClick={onPausePlaying}
          />
        )
      default:
        return null
    }
  }, [recordingStatus])

  return (
    <div className="relative max-h-14 h-full flex justify-between items-center px-8 mb-12 z-30">
      <MButton
        type={ButtonTypeEnum.Icon}
        onClick={onRemoveRecording}
        disabled={recordingStatus === RecordingStatusEnum.NotStarted}
      >
        <TrashIcon color="#282626" />
      </MButton>
      <div className="absolute-center top-0">
        {controlButton}
      </div>
      {isRecorded ? (
        <p className="text-dark-grey text-lg font-medium">{currentTime}</p>
      ) : null}
      {recordingStatus === RecordingStatusEnum.NotStarted && showSwitchCamera ? (
        <MButton onClick={handleSwitchCamera} type={ButtonTypeEnum.Icon} buttonClassName="w-12 h-12">
          <SwitchCameraIcon className="w-12 h-12 text-dark-grey" />
        </MButton>
      ) : null}
    </div>
  )
}

export default memo(RecorderControls)
