import React, { FC, useRef } from "react"

import { TERMS_AND_CONDITIONS_LINK } from "constants/menu.constants"
import { usePlayer } from "context/player.context"
import { ButtonTypeEnum } from "types/common.types"
import MButton from "../base/MButton"

// assets
import { ReactComponent as CrossIcon } from "assets/icons/cross-icon.svg"
import { ReactComponent as MessageIcon } from "assets/icons/message-icon.svg"
import { useBranding } from "../../context/branding.context"
import { useBodyScrollLock } from "../../hooks/body-scroll-lock.hook"
import { createPortal } from "react-dom"
import RecordTypeSwitch from "../record-type-switch/RecordTypeSwitch.component"

const NewMessagePopup: FC = () => {
  const { setShowNewMessagePopup, ecMessageId } = usePlayer()
  const { popupDescriptionText } = useBranding()

  const modalRef = useRef<HTMLDivElement>(null!)

  const handleClickOnCloseModal = () => {
    setShowNewMessagePopup(false)
  }

  useBodyScrollLock(modalRef)

  return createPortal(
    <div
      ref={modalRef}
      className="flex fixed-center w-screen h-screen z-40 justify-center"
    >
      <div
        className="relative self-center max-w-md w-full h-fit m-4 flex flex-col p-5 pb-9 bg-white rounded-2xl shadow-md z-30">
        <MButton
          type={ButtonTypeEnum.Icon}
          buttonClassName="absolute top-5 right-3"
          onClick={handleClickOnCloseModal}
        >
          <CrossIcon className="text-black w-5 h-5" />
        </MButton>
        <MessageIcon
          className="mt-3 mb-5 text-black self-center"
          color="black"
          width={24}
          height={24}
        />
        <h1 className="text-xl text-gray-900 font-bold text-center mb-4">
          Teile dein Glück
        </h1>
        <p className="text-base text-black mb-6">
          {
            ecMessageId
              ? "Bitte beachten Sie: Durch das Veröffentlichen einer neuen Nachricht, überschreiben Sie automatisch Ihre empfangene Nachricht."
              : popupDescriptionText
          }
        </p>
        <p className="text-gray-900 font-bold text-base mb-4">
          Wählen Sie Ihren Aufnahmetyp
        </p>
        <RecordTypeSwitch />
        <a
          target="_blank"
          rel="noreferrer"
          className="text-center mt-6 text-sm underline"
          href={TERMS_AND_CONDITIONS_LINK}
        >
          AGBs
        </a>
      </div>
      <div className="absolute w-screen h-screen overflow-hidden bg-black/50"
           onClick={handleClickOnCloseModal}
      />
    </div>, document.body,
  )
}

export default NewMessagePopup
