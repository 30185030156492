import { MutableRefObject, useCallback, useEffect, useState } from "react"
import { StreamPlayerApi } from "@cloudflare/stream-react"
import { round } from "../utils/common.utils"

export const useStream = (
  streamRef: MutableRefObject<StreamPlayerApi | null>,
) => {
  const [isPlaying, setIsPlaying] = useState(
    streamRef.current?.autoplay ?? false,
  )
  const [isFinished, setIsFinished] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const [currentTime, setCurrentTime] = useState(
    streamRef.current?.currentTime ?? 0,
  )

  const startPlaying = useCallback(() => {
    streamRef.current?.play()
    setIsPlaying(true)
  }, [])

  const pausePlaying = useCallback(() => {
    streamRef.current?.pause()
    setIsPlaying(false)
  }, [])

  const replay = useCallback(() => {
    setIsFinished(false)
    startPlaying()
  }, [startPlaying])

  const leavePlayer = useCallback(() => {
    pausePlaying()
    setCurrentTime(0)
    setIsFinished(false)
  }, [pausePlaying])

  const onEnded = useCallback(() => {
    setIsFinished(true)
  }, [])

  const onCanPlay = useCallback(() => {
    setCanPlay(true)
  }, [])

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(round(streamRef.current?.currentTime ?? 0))
  }, [])

  useEffect(() => {
    return () => {
      setCanPlay(false)
    }
  }, [])

  return {
    isPlaying,
    isFinished,
    startPlaying,
    pausePlaying,
    replay,
    leavePlayer,
    onEnded,
    currentTime,
    onCanPlay,
    canPlay,
    onTimeUpdate,
  }
}
