import React, { FC } from "react"
import achkarrenLogo from "../../assets/images/achkarren-logo.svg"
import rossmanLogo from "../../assets/images/rossman-logo.svg"

const BrandInfo: FC = () => {
  return (
    <div className="flex flex-col w-full self-center p-4">
      <span className="text-center mb-4 text-sm font-semibold uppercase">
        Eine kooperation von
      </span>
      <div className="flex flex-col self-center gap-x-10 gap-y-3 md:flex-row">
        <a href="https://achkarrer-wein.com/"
           target="_blank"
           rel="noreferrer"
           className="flex items-center justify-center p-10 bg-beige">
          <img src={achkarrenLogo} alt="achkarren logo" width={264} height={54} />
        </a>
        <a href="https://www.rossmann.de/de/lebensmittel-gluecksgriff-grauer-burgunder-und-chardonnay/p/4000904777127"
           target="_blank"
           rel="noreferrer"
           className="flex items-center justify-center p-10 bg-beige">
          <img src={rossmanLogo} alt="rossman logo" width={261} height={73} />
        </a>
      </div>
    </div>
  )
}

export default BrandInfo
