import React, { forwardRef, PropsWithChildren, useMemo } from "react"
import { ButtonTypeEnum } from "types/common.types"

interface MButtonProps {
  color?: string
  type?: ButtonTypeEnum
  onClick?: () => void
  iconClassName?: string
  buttonClassName?: string
  disabled?: boolean
}

const MButton = forwardRef<HTMLButtonElement, PropsWithChildren<MButtonProps>>(
  (
    {
      type = ButtonTypeEnum.Text,
      onClick,
      buttonClassName,
      disabled = false,
      children,
    },
    ref,
  ) => {
    const className = useMemo(() => {
      switch (type) {
        case ButtonTypeEnum.Icon:
          return `flex justify-center items-center`
        default:
          return "flex h-11 justify-center items-center box-border px-7 py-2 text-white bg-red hover:bg-dark-red rounded-5xl gap-3 text-base font-inter font-bold"
      }
    }, [type])

    return (
      <button
        ref={ref}
        className={`${className} ${buttonClassName} ${
          disabled ? "opacity-40" : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    )
  },
)

MButton.displayName = "MButton"

export default MButton
