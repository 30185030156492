import React, { FC, memo } from "react"

import { ButtonTypeEnum, ContentTypeEnum } from "types/common.types"
import Timer from "../shared/Timer.component"
import MButton from "../base/MButton"

// assets
import { ReactComponent as CrossIcon } from "assets/icons/cross-icon.svg"
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg"
import cn from "classnames"

interface RecorderHeaderProps {
  contentType: ContentTypeEnum
  onClose: () => void
  onUpload: () => void
  startTimer: boolean
  showUploadButton: boolean
  onTimeChange?: (time: number) => void
}

const RecorderHeader: FC<RecorderHeaderProps> = ({
                                                   contentType,
                                                   onClose,
                                                   onUpload,
                                                   startTimer,
                                                   showUploadButton,
                                                   onTimeChange,
                                                 }) => {
  return (
    <div className="relative max-h-20 h-full flex gap-4 px-4 py-4 mb-3 justify-between items-center">
      <MButton type={ButtonTypeEnum.Icon} onClick={onClose}>
        <CrossIcon color="#282626" width={20} height={20}/>
      </MButton>
      <MButton
        buttonClassName={cn({
          "invisible": !showUploadButton,
        })}
        onClick={onUpload}>
        Veröffentlichen
        <ArrowIcon />
      </MButton>
      {!showUploadButton ? (
        <Timer
          type={contentType}
          start={startTimer}
          onTimeChange={onTimeChange}
        />
      ) : false}
    </div>
  )
}

export default memo(RecorderHeader)
