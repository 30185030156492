export enum RecordingStatusEnum {
  NotStarted = "not-started",
  Preparing = "preparing",
  Recording = "recording",
  Stopped = "stopped",
  Reviewing = "reviewing",
  Reviewed = "reviewed",
}

export enum FacingModeEnum {
  User = "user",
  Environment = "environment",
}
