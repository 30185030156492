import { useEffect, useRef, useState } from "react"

export const useTimer = (maxSeconds: number, onStop?: () => void, start = true) => {
  const [timer, setTimer] = useState(maxSeconds)
  const [isStopped, setIsStopped] = useState(false)
  const timerId = useRef<number>()
  const clear = () => {
    window.clearInterval(timerId.current)
  }

  useEffect(() => {
    if (!start) return

    timerId.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear()
  }, [start])

  useEffect(() => {
    if (timer === 0) {
      clear()
      setIsStopped(true)
      onStop?.()
    }
  }, [timer])

  return { timer, isStopped }
}
