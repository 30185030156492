import { RefObject, useEffect, useState } from "react"
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js"
import RecordPlugin from "wavesurfer.js/dist/plugins/record"
import { round } from "../utils/common.utils"

export const useWavesurfer = (
  containerRef: RefObject<HTMLElement>,
  options: Omit<WaveSurferOptions, "container">,
) => {
  const [wavesurfer, setWavesurfer] = useState<any>(null)
  const [recorder, setRecorder] = useState<any>(null)
  const [isPlaying, setIsPlaying] = useState(options.autoplay ?? false)
  const [isFinished, setIsFinished] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState<number | null>(null)

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef.current) return

    const ws = WaveSurfer.create({
      ...options,
      container: containerRef.current,
    })

    setCurrentTime(0)
    setIsPlaying(false)

    const subscriptions = [
      ws.on("play", () => {
        setIsPlaying(true)
        setIsFinished(false)
      }),
      ws.on("pause", () => setIsPlaying(false)),
      ws.on("finish", () => setIsFinished(true)),
      ws.on("timeupdate", (currentTime: number) =>
        setCurrentTime(round(currentTime)),
      ),
      ws.on("ready", (duration: number) => setDuration(round(duration))),
    ]

    if (!options.url) {
      const wavesurferRecorder = ws?.registerPlugin(RecordPlugin.create())
      setRecorder(wavesurferRecorder)
    }

    setWavesurfer(ws)

    return () => {
      subscriptions.forEach((unsub) => unsub())
      ws?.destroy()
    }
  }, [options, containerRef])

  return { wavesurfer, recorder, isPlaying, isFinished, currentTime, duration }
}
